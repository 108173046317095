<template>
	<footer id="footer" class="gray-bg">
		<p class="footcopyright">
			website created by <a href="http://ninapalumbo.com/" class="copyrightlink" target="blank">Nina Palumbo</a> © 2024
		</p>
	</footer>
</template>

<script setup></script>

<style>
#footer {
	font-size: 14px;
	color: #96b7a5;
	position: fixed;
	bottom: 0;
	margin: 0;
	left: 0;
	right: 0;
	padding: 0.2rem 0rem;
	text-align: center;
}
#footer:hover {
	cursor: pointer;
}

.copyrightlink {
	color: white;
	font-weight: bold;
}
/* NAVIGATION */
.top-bar {
	background-color: var(--gray-color);
}
</style>
