<template>
	<!-- eslint-disable -->

	<div id="title-area">
		<router-link :to="`/`"><img src="../assets/img/logo4.png" class="logo" /></router-link>
	</div>
	<div class="hamburger-bookmark"></div>

	<Slide id="hamburger" :closeOnNavigation="true" noOverlay right>
		<img width="200px" style="margin: -40px auto -20px auto" src="../assets/img/pug-tophat-black.png" />
		<span class="squiggle black"> &nbsp;&nbsp;&nbsp;&nbsp; glysquasdaoasdad</span>

		<nav class="nav" v-if="displayRoutes">
			<ul id="navList" v-for="route in routerlinks">
				<li>
					<router-link v-if="route.title !== 'Blog'" :class="'anchor-links'" :to="`${route.path}`" :id="route.title">{{
						route.title
					}}</router-link>
					<a v-else target="_blank" @click="goToBlog()">Blog </a>
					<!-- <i class="fa-solid fa-feather"></i> -->
					&nbsp;&nbsp;
				</li>
			</ul>
		</nav>
	</Slide>
</template>

<script setup>
import { Slide } from "vue3-burger-menu" // import the CSS transitions you wish to use, in this case we are using `Slide`
import { defineProps, ref } from "vue"

const props = defineProps({
	routerlinks: Array,
})

const goToBlog = () => {
	console.log(window.location.host, "Host")
	if (window.location.host === "localhost:8080" || window.location.host === "127.0.0.1:8080") {
		window.location.href = "https://positively-pragmatic.com" + "/blog/"
	} else {
		window.location.href = window.location.origin + "/blog/"
	}
}

const displayRoutes = ref(props.routerlinks)
</script>
<style>
img.logo {
	margin: 0 auto;
	position: absolute !important;
	top: 0;
	padding: 1rem;
	max-width: 8em;
	z-index: 998;
}
.name,
#title-area img {
	position: absolute !important;
}

.hamburger-bookmark {
	height: 100px;
	background-color: var(--main-red);
	width: 120px;
	z-index: 997;
	position: absolute;
	top: 0;
	right: 0;
	margin-right: -10px;
	clip-path: polygon(80% 0, 80% 80%, 50% 100%, 20% 80%, 20% 0);
}
.nav {
	display: block;
	line-height: 2;
	flex-direction: column !important;
	text-wrap: nowrap;
	color: var(--secondary-color);
	text-shadow: 1px 1px 1px var(--secondary-color);
}

.nav li,
.nav ul li > a {
	position: relative !important;
	font-weight: bold;
	font-size: 2rem;
	text-align: right;
	color: black;
	line-height: 3rem;
}

.nav li,
.nav ul li > a:hover {
	font-weight: bold;
	color: var(--secondary-color) !important;
	cursor: pointer;
}

nav a.router-link-exact-active {
	color: var(--light-color);
}
#hamburger {
	float: right !important;
	position: absolute;
	z-index: 999;
	top: 0;
	right: 3.25rem;
	color: #325b46;
}

.bm-burger-button {
	position: static;
	width: 36px;
	height: 30px;

	cursor: pointer;
}
.bm-burger-bars {
	background-color: var(--secondary-color);
	z-index: 999;
}
.line-style {
	position: absolute;
	height: 20%;
	left: 0;
	right: 0;
}
.cross-style {
	position: absolute;
	top: 18px;
	right: 12px;
	cursor: pointer;
}
.bm-cross {
	background-color: var(--primary-color);
}
.bm-cross-button {
	height: 24px;
	width: 24px;
}

.bm-menu {
	background: url("../assets/img/paper.avif");
	height: 100%; /* 100% Full-height */
	position: fixed; /* Stay in place */
	z-index: 1000; /* Stay on top */
	top: 0;
	opacity: 0.99;
	right: 0 !important;
	overflow-x: hidden; /* Disable horizontal scroll */
	padding-top: 60px; /* Place content 60px from the top */
	transition: 0.1s; /*0.5 second transition effect to slide in the sidenav*/
	clip-path: polygon(10% 0%, 10% 12%, 10% 21%, 9% 33%, 9% 48%, 12% 62%, 10% 74%, 11% 85%, 9% 100%, 100% 100%, 100% 0%);
}

.bm-overlay {
	background-image: url("../assets/img/floral.jpg");
	background-blend-mode: color-burn;
}
.bm-item-list {
	color: #b8b7ad;
	margin-left: 10%;
	font-size: 20px;
}
.bm-item-list > * {
	display: flex;
	text-decoration: none;
	padding: 0.7em;
}
.bm-item-list > * > span {
	margin-left: 10px;
	font-weight: 700;
	color: white;
}
</style>
