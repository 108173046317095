import { createRouter, createWebHashHistory } from "vue-router"
import HomeView from "../views/HomeView.vue"
import EducationView from "../views/EducationView.vue"
import ContactView from "../views/ContactView.vue"
import WorkView from "../views/WorkView.vue"
import AboutView from "../views/AboutView.vue"

const routes = [
	{
		path: "/",
		name: "HomeView",
		component: HomeView,
	},

	{
		path: "/contact",
		name: "ContactView",
		component: ContactView,
	},
	{
		path: "/about",
		name: "AboutView",
		component: AboutView,
	},
	{
		path: "/education",
		name: "EducationView",
		component: EducationView,
	},
	{
		path: "/work",
		name: "WorkView",
		component: WorkView,
	},
	{
		path: "/education",
		name: "EducationView",
		component: EducationView,
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

export default router
