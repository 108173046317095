<script setup>
import { ref, onMounted } from "vue"
import NavComponent from "./components/NavComponent.vue"
import FooterComponent from "./components/FooterComponent.vue"

const routerlinks = ref()
const renderApp = ref(false)

const colorSchemes = {
	original: {
		primary: "#325b46",
		secondary: "#96b7a5",
		tertiary: "#2c3e50",
		quatric: "#e6e7e8",
		light: "#42b983",
	},
}

const chosenTheme = ref("original")
console.log(colorSchemes, chosenTheme)
onMounted(() => {
	routerlinks.value = [
		{ title: "About", path: "/about" },
		{ title: "Blog", path: "/blog" },
		{ title: "Education", path: "/education" },
		{ title: "Work", path: "/work" },
		{ title: "Contact", path: "/contact" },
	]
	renderApp.value = true
})
</script>
<template>
	<!-- eslint-disable -->
	<div v-if="routerlinks && renderApp">
		<NavComponent :routerlinks="routerlinks"></NavComponent>
		<router-view id="router-view" v-slot="{ Component }">
			<transition name="fade" mode="out-in" :duration="500">
				<component :is="Component" />
			</transition>
		</router-view>
		<FooterComponent :routerlinks="routerlinks"></FooterComponent>
	</div>
</template>

<style></style>
