<template>
	<div class="small-12 medium-12 large-12">
		<div class="gray-bg container">
			<h2 class="head">Work</h2>
			<ul class="work-list text">
				<li>
					<em class="fictitle">“Education of Nothing”</em> Nonfiction reflective essay; Seeds Literary Arts Journal, May 2011
				</li>
				<br />
				<li><em class="fictitle">“Good Day”</em> Short fiction; Seeds Literary Arts Journal, December 2011</li>
				<br />
				<li>
					<em class="fictitle">“The Kids and Their Fires”</em> Short fiction/fantasy; Seeds Literary Arts Journal, December
					2012
				</li>
				<br />

				<li>
					<em class="fictitle">“Real Estate”</em> Short fiction;
					<a href="http://www.magcloud.com/browse/issue/590599" class="worklink" target="_blank"> Hemingway’s Playpen</a>,
					June 2013
				</li>
				<br />

				<li><em class="fictitle">“Ten Days”</em> Flash fiction; Seeds Literary Arts Journal, December 2013</li>
				<br />
				<li><em class="fictitle">“Click Clack”</em> Short fiction/fantasy; Seeds Literary Arts Journal, May 2014</li>
				<br />
				<li>
					<em class="fictitle">“Peanut Butter”</em> Short fiction;
					<a href="http://www.tayenlane.com/procyon-short-story-anthology-2014/" class="worklink" target="_blank">
						Tayen Lane Publishing</a
					>, September 2014
				</li>
				<br />
				<li>
					<em class="fictitle">“Paper Cranes”</em> Short fiction/fantasy;
					<a href="http://thoughtcatalog.com/nergal-malham/2015/03/paper-cranes/" class="worklink" target="_blank">
						Thought Catalog</a
					>, March 2015
				</li>
				<br />
				<li>
					<em class="fictitle">“Nora's Key”</em> Short fiction;
					<a href="https://www.gasherjournal.com/single-post/nergal-malham" class="worklink" target="_blank">
						GASHER Journal</a
					>, January 2021
				</li>
				<br />
				<li>
					<em class="fictitle">“Temperance”</em> Short fiction;
					<a href="https://thebookendsreview.com/2021/11/08/temperance/" class="worklink" target="_blank">
						The Bookends Review</a
					>, November 2021
				</li>
				<br />
				<li>
					<em class="fictitle">"The Buzzer"</em> Short fiction;
					<a href="https://www.grimandgilded.com/nergal-malham" class="worklink" target="_blank"> Grim & Gilded</a>, February
					2022
				</li>
			</ul>
		</div>
	</div>
</template>
<script setup></script>

<style scoped>
.work-list {
	color: #ffffff;
	text-align: center;
	line-height: 1.2;
}
a.worklink {
	font-weight: bolder;
	color: var(--secondary-color);
}

.fictitle {
	color: var(--secondary-color);
	font-size: larger;
}
</style>
