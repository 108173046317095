<template>
	<div class="small-12 medium-12 large-12">
		<div class="gray-bg container">
			<h2 class="head">Education</h2>

			<p class="text">
				<em>Roosevelt University, Chicago, IL </em><br />
				<b>Masters of Fine Arts in Creative Writing</b><br />
				August 2016 – December 2018
			</p>
			<br /><br />
			<p class="text">
				<em>Northeastern Illinois University, Chicago, IL</em><br />
				<b>Bachelors of Science in Accounting</b><br />
				August 2010 – 2014
			</p>
		</div>
	</div>
</template>

<script setup></script>
<style scoped>
.section2,
.gray-bg,
#router-view {
	width: 100% !important;
}
</style>
