<template>
	<div class="small-12 medium-12 large-12">
		<div class="gray-bg container">
			<div class="head">
				<div class="flex flex-col items-center justify-center">
					<img src="../assets/img/pug-tophat-whte.png" class="pug footerpug animated shake box" />
					<p class="text">Want to follow me, or work together?</p>
					<div class="flex flex-row justify-center">
						<a href="https://twitter.com/nelgarthehorrid" target="_blank"
							><img src="../assets/img/twiticon.png" class="contact-icons footerpadding"
						/></a>
						<a href="mailto:pug@nergalmalham.com"><img src="../assets/img/emailicon.png" class="contact-icons" /></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script async>
!(function (d, s, id) {
	var js,
		fjs = d.getElementsByTagName(s)[0]
	if (!d.getElementById(id)) {
		js = d.createElement(s)
		js.id = id
		js.src = "//platform.twitter.com/widgets.js"
		fjs.parentNode.insertBefore(js, fjs)
	}
})(document, "script", "twitter-wjs")
</script>
<script setup></script>

<style scoped>
.pug {
	max-width: 10rem;
}
.contact-icons {
	max-width: 5rem;
}
</style>
