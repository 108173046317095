<template>
	<div class="section">
		<div class="small-12 medium-12 large-12">
			<h1 id="welcome" class="animated fadeIn">Nergal Malham</h1>
			<p class="head-text tagline body animated fadeInDown">
				<span class="squiggle">d</span>
				Chicago-based Writer
				<span class="squiggle">s</span><br />
				<span id="blog-link"
					><a target="_blank" @click="goToBlog()"><i class="fa-solid fa-book-open"></i>&nbsp;Blog</a>&nbsp;&nbsp;&nbsp;
				</span>
			</p>
			<p class="head-text body animated fadeInDown"><AboutView /><br /></p>
		</div>
	</div>
</template>
<script setup>
import { AboutView } from "./AboutView.vue"

const goToBlog = () => {
	console.log(window.location.host, "Host")
	if (window.location.host === "localhost:8080" || window.location.host === "127.0.0.1:8080") {
		window.location.href = "https://positively-pragmatic.com/" + "/blog/"
	} else {
		window.location.href = window.location.origin + "/blog/"
	}
}
</script>
<style scoped>
.section {
	min-height: 100vh;
	display: block;
	flex-direction: column;
	align-self: center;
	justify-content: center;
	align-items: center;
}
.head-text {
	color: #97b7a5;
	text-align: center;
	font-size: 1.3em;
}
.red-strip {
	background-color: var(--main-red);
	width: 100%;
	height: 350px !important;
	box-shadow: 2 3px 10px rgb(0 0 0 / 0.2);
	opacity: 1;
}

#welcome {
	color: #ffffff;
	font-family: var(--font-display);
	font-size: 6em;
	text-shadow: 3px 3px 6px rgb(0, 0, 0);
}

.tagline {
	word-wrap: break-word;
	margin-top: -1rem !important;
	font-size: 2rem;
	text-shadow: 3px 3px 6px rgb(0, 0, 0);
}
#blog-link {
	cursor: pointer;
}
</style>

<script>
export default {
	name: "HomeView",
	components: {},
}
</script>
